import { toDate } from '@/utils/date'
import filters from '@/utils/filters'
import { TDATO_TDATO_SISTEMA } from '@/utils/consts'

export default {
  async update (Vue, id, formData) {
    let valor = formData.valor
    if (TDATO_TDATO_SISTEMA.tiposDate.includes(formData.tdato_sistema.tdato_sistema.tipo)) {
      valor = filters.shortDate(toDate(valor))
    }
    await Vue.$offline.datoSistema.updateSync({
      iddato_sistema: id,
      idtdato_sistema: formData.tdato_sistema.tdato_sistema.idtdato_sistema,
      valor: valor,
      orden: formData.orden,
    })
  },
}
